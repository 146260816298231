import CloseIcon from "~/assets/icons/Close.svg?react";
import { useTranslation } from "react-i18next";
import {
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle
} from "~/components/ui/drawer";
import { FriendsLevelBonusByLevel } from "./useBonusesForFriendsByLevel/types";
import Button from "~/components/ui/button";
import { Tokens } from "~/components/balance/Tokens/Tokens";
import { formatTokens } from "~/components/balance/Tokens/utils";
import { NumberSign } from "~/constants/signs";
import { Currency } from "~/components/balance/constants";
import { IMAGE_BY_CURRENCY } from "~/components/balance/Tokens/constants";

const NUMBER_SHORTEN_FROM = 1e3;
const tokenImage = IMAGE_BY_CURRENCY[Currency.nativeCoin];

type Props = {
  bonuses: FriendsLevelBonusByLevel[];
};

export const FriendsLevelRaisingDetail = ({ bonuses }: Props) => {
  const { t } = useTranslation("referral");

  return (
    <DrawerContent>
      <DrawerHeader>
        <DrawerTitle>{t("levelRaising.popup.title")}</DrawerTitle>
        <DrawerClose>
          <CloseIcon className="w-6 h-6 text-background-quaternary hover:text-background-tertiary" />
        </DrawerClose>
      </DrawerHeader>
      <DrawerBody className="flex flex-col items-center gap-2">
        <div className="w-full flex gap-5 flex-col">
          <div className="t-p-tertiary">
            {t("levelRaising.popup.description")}
          </div>
          <div className="flex flex-col gap-1">
            <div className="t-title-smallest flex gap-3 uppercase px-4 py-1 justify-between text-foreground-secondary font-bold">
              <span>{t("levelRaising.popup.column.level")}</span>
              <span>{t("levelRaising.popup.column.bonus")}</span>
            </div>
            {bonuses.map(({ count, alternativeCount, level, name }) => (
              <div
                key={level}
                className="flex gap-3 p-4 items-center rounded-24 bg-background-primary/[0.04] backdrop-blur-[22px] overflow-hidden"
              >
                <span className="t-title-tertiary min-w-[30px] text-center font-bold">
                  {level}
                </span>
                <span className="flex-1 t-h6">{name}</span>

                {alternativeCount ? (
                  <div className="t-title-tertiary flex items-center gap-2 font-bold">
                    <img className="w-6 h-6" src={tokenImage} />
                    {t("levelRaising.popup.column.oneAmountOrAnother", {
                      amount: formatTokens(count, {
                        prefix: NumberSign.plus,
                        numberToShortenFrom: NUMBER_SHORTEN_FROM
                      }),
                      alternativeAmount: formatTokens(alternativeCount, {
                        prefix: NumberSign.plus,
                        numberToShortenFrom: NUMBER_SHORTEN_FROM
                      })
                    })}
                  </div>
                ) : (
                  <Tokens
                    count={count}
                    coinClassName="w-6 h-6 mr-2"
                    textClassName="t-title-tertiary font-bold"
                    formatOptions={{
                      prefix: "+",
                      numberToShortenFrom: 1e3
                    }}
                    withCoin
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </DrawerBody>
      <DrawerFooter>
        <DrawerClose>
          <Button size="lg" fullWidth>
            {t("actions.gotIt", { ns: "common" })}
          </Button>
        </DrawerClose>
      </DrawerFooter>
    </DrawerContent>
  );
};
