/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { gql } from '@urql/core';
export type DTOPowerCardFragment = { __typename?: 'PowerCardProposal', id: any, userLevel: number, sellingPrice: number, sellingLevel: number, sellingPowerBoost: number, powerCard?: { __typename?: 'PowerCard', title: string, description: string, imageUrl: string, maxLevel: number } | null };

export const PowerCardFragmentDoc = gql`
    fragment PowerCardFragment on PowerCardProposal {
  id
  powerCard {
    title
    description
    imageUrl
    maxLevel
  }
  userLevel
  sellingPrice
  sellingLevel
  sellingPowerBoost
}
    `;