import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import resourcesToBackend from "i18next-resources-to-backend";
import commonEn from "./locales/en/common.json";
import powerEn from "./locales/en/power.json";
import boostEn from "./locales/en/boost.json";
import dailyBonusEn from "./locales/en/dailyBonus.json";
import rewardsEn from "./locales/en/rewards.json";
import friendsEn from "./locales/en/friends.json";
import farmingEn from "./locales/en/farming.json";
import roadmapEn from "./locales/en/roadmap.json";
import onboardingEn from "./locales/en/onboarding.json";
import referralEn from "./locales/en/referral.json";
import spinsEn from "./locales/en/spins.json";
import premiumShopEn from "./locales/en/premiumShop.json";
import aiEn from "./locales/en/ai.json";
import airdropEn from "./locales/en/airdrop.json";

const resources = {
  en: {
    power: powerEn,
    boost: boostEn,
    common: commonEn,
    dailyBonus: dailyBonusEn,
    farming: farmingEn,
    friends: friendsEn,
    onboarding: onboardingEn,
    rewards: rewardsEn,
    roadmap: roadmapEn,
    referral: referralEn,
    spins: spinsEn,
    premiumShop: premiumShopEn,
    ai: aiEn,
    airdrop: airdropEn
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(
    resourcesToBackend((language: string, namespace: string) => {
      // no reason there is a language called 'dev', just passed it away
      if (language === "dev") return;
      return import(`./locales/${language}/${namespace}.json`);
    })
  )
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    // resources: {
    //   en: {
    //     translation: {
    //       "Welcome to React": "Welcome to React and react-i18next"
    //     }
    //   }
    // },
    resources,
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });
