import { startFarming } from "./startFarming";
import { boostModel } from "./boostModel";
import { claimRefereeReward } from "./claimRefereeReward";
import { claimFarmingReward } from "./claimFarmingReward";

const mutationsIndex = {
  startFarming,
  boostModel,
  claimRefereeReward,
  claimFarmingReward
};

export default mutationsIndex;
