export const WITH_NAVIGATION_LAYOUT = "/_withNavigation";

export enum RoutePath {
  farm = "/",
  ai = "/ai",
  boost = "/boost",
  power = "/power",
  friends = "/friends",
  rewards = "/rewards",
  leaders = "/leaders",
  referral = "/referral",
  roadmap = "/roadmap",
  dailyBonus = "/dailyBonus",
  spins = "/spins",
  premiumShop = "/premiumShop",
  airdrop = "/airdrop"
}
