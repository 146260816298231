import { useLocation } from "@tanstack/react-router";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { RoutePath } from "~/constants/routes";

const PAGE_TITLE_BY_ROUTE: Record<RoutePath, string> = {
  [RoutePath.farm]: "Farming",
  [RoutePath.ai]: "Power",
  [RoutePath.friends]: "Friends",
  [RoutePath.rewards]: "Rewards",
  [RoutePath.leaders]: "Leaders",
  [RoutePath.referral]: "Referral",
  [RoutePath.roadmap]: "Roadmap",
  [RoutePath.dailyBonus]: "Daily Bonus",
  [RoutePath.spins]: "Spins",
  [RoutePath.premiumShop]: "Premium Shop",
  [RoutePath.power]: "Power",
  [RoutePath.boost]: "Boost",
  [RoutePath.airdrop]: "Airdrop"
};

export default function usePageTitle(customTitle?: string) {
  const { t } = useTranslation("common");
  const documentDefined = typeof document !== "undefined";
  const originalTitle = useRef(documentDefined ? document.title : null);
  const location = useLocation();
  const title = useMemo(() => {
    if (customTitle) {
      return customTitle;
    }

    return `${t("appName")}:${PAGE_TITLE_BY_ROUTE[location.pathname as RoutePath]}`;
  }, [customTitle, location.pathname, t]);

  useEffect(() => {
    if (!documentDefined) return;

    if (document.title !== title) document.title = title;

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      const title = originalTitle.current;
      if (title) {
        document.title = title;
      }
    };
  }, [documentDefined, title]);
}
