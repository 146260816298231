/* eslint-disable */
// @ts-nocheck
import * as Types from '../../../../../declarations/graphql/types';

import { DTOPowerCardFragment } from './powerCardFragment.generated';
import { gql } from '@urql/core';
import { PowerCardFragmentDoc } from './powerCardFragment.generated';
import * as Urql from 'urql';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type DTOPowerCardCategoriesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type DTOPowerCardCategoriesQuery = { __typename?: 'Query', powerCardsCategories: Array<{ __typename?: 'Category', id: Types.DTOPowerCardCategory, name: string, minModelLevel: number, cardsProposals: Array<(
      { __typename?: 'PowerCardProposal' }
      & DTOPowerCardFragment
    )> }> };


export const PowerCardCategoriesQueryDocument = gql`
    query PowerCardCategoriesQuery {
  powerCardsCategories {
    id
    name
    minModelLevel
    cardsProposals {
      ...PowerCardFragment
    }
  }
}
    ${PowerCardFragmentDoc}`;

export function usePowerCardCategoriesQuery(options?: Omit<Urql.UseQueryArgs<DTOPowerCardCategoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<DTOPowerCardCategoriesQuery, DTOPowerCardCategoriesQueryVariables>({ query: PowerCardCategoriesQueryDocument, ...options });
};